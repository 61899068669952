import { DocumentType, FeatureType } from '@innedit/innedit-type';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '../../../containers/Espace/props';
import ListItem from './index';

const ListItemCaracteristique: FC<ListItemProps<FeatureType>> = ({
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] = useState<DocumentType<FeatureType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  return (
    <ListItem displayActionId doc={doc} index={index} onClick={onClick}>
      <strong>{doc.libelle}</strong>
    </ListItem>
  );
};

export default ListItemCaracteristique;
